<template>
  <div class="privacyPolicy-container pt-1">
    <div class="title text-center text-2xl font-bold py-5">
      Privacy Policy for the Participants of ASCVTS 2024 Wuhan
    </div>
    <div class="p-0">
      <div id="privacy" class="h-83 overflow-y-auto overflow-x-hidden p-4 border-bottom">
        <!-- <h4 class="mb-3">This policy was last updated on June 28, 2022.</h4> -->
        <p>This website is operated by the secretariat (hereafter referred to as ‘Secretariat’ or ‘We’) of the 32<sup>nd</sup>
          Annual Meeting of the Asian Society for Cardiovascular and Thoracic Surgery (ASCVTS 2024, hereafter referred
          to as ‘Conference’), and this privacy policy applies to this website only.</p>
        <p>At www.ascvts2024wuhan.org.cn (hereafter referred to as ‘Website’), the Secretariat respects its participants
          and understands that you are concerned about privacy. We are posting this privacy policy to let you know what
          kind of information we collect, how it is handled, with whom it may be shared, what choices you have regarding
          the Secretariat’s use of your information, and how you may access some of the data you provide to the
          Secretariat.</p>
        <p>As the Secretariat continues to develop the Website and take advantage of technologies to improve the
          services we offer, the privacy policy may change. We therefore encourage you to refer to this policy on an
          ongoing basis so that you understand the Secretariat’s current privacy policy.</p>
        <p>If you have any questions about this policy, please contact our Privacy Protection Manager at <a
          href="mailto:ASCVTS2024WUHAN@hotmail.com">ASCVTS2024WUHAN@hotmail.com</a>.</p>
        <h4 class="mt-3">What Information Do We Collect?</h4>
        <p>The SECRETARIAT collects various types of information depending upon how you use the WEBSITE:</p>
        <ul class="disc">
          <li>When you create account and register for the Conference, we collect your name, email address, affiliation
            as well as the position and some other contact information. All the information collected in this procedure
            will automatically be saved in “My Page” for ease of reference and to help you to go on to the next step
            (registration, abstract submission, etc.) as quickly as possible.
          </li>
          <li>Our primary goal in collecting personal information including your first name, last name, phone number(s),
            valid email and credit card data is to provide you with a smooth, efficient, customized registration
            experience.
          </li>
          <li>When you submit a question to the administrator by e-mail, we need your email address to respond; you may
            also provide us with additional information to help the person in charge at the Secretariat answer your
            question.
          </li>
        </ul>
        <h4 class="mt-3">What Are Cookies and Do We Use Them?</h4>
        <p>A cookie is a small piece of information sent by a website, which is saved on your hard disk by your
          computer's browser. It holds information a site may need to interact with you and personalize your experience.
          At the Website, we use two kinds of cookies: session cookies and persistent cookies. Session cookies exist
          only for as long as your browser remains open. Once you exit your browser, they go away. The Secretariat uses
          session cookies to maintain information we need to have in order for you to register for the Conference. For
          example, the Session ID cookie that we ask your browser to hold retains the ID for My Page. Without the
          Session ID cookie, you cannot add and modify any information that you input at the time of your sign-up.</p>
        <p>Persistent cookies, in contrast, last from visit to visit; they do not go away when you exit your browser. At
          the Website, we use persistent cookies to give you a more personalized browse and to help you navigate the
          Website more efficiently. The Secretariat will use your information to enhance your site experience. However,
          you can use the Website without accepting a persistent cookie. To do so, you should set your browser options
          to reject persistent cookies. Alternatively, you can set your browser to notify you when you receive a cookie,
          which gives you the opportunity to decide whether you want to accept it or not. In many instances, the Help
          button on your browser toolbar will tell you how you can take these steps.</p>
        <p>Cookies can be used by a website to recognize you. But that does not necessarily mean any personal
          information is stored in the cookies. At the Website, we store no personal information about you in the
          cookies. Anything you choose to tell us about yourself (such as your address and email address) is stored
          safely and separately on our secured servers, and you need to provide a password to access it.</p>
        <p>The Secretariat also uses cookies to look at how groups, rather than individuals, use the Website. In our
          administration page, we can observe which aisles and departments are most heavily trafficked and determine the
          best method to improve the experience for visitors. On the web, cookies help us develop a similar
          understanding so that we can continue to improve the arrangement, product offerings, registration and abstract
          submission at the Website.</p>
        <h4 class="mt-3">How Do We Use Your Information?</h4>
        <p>The SECRETARIAT may use your information in a number of ways, including the following:</p>
        <ul class="disc">
          <li>To prepare the name tag, meals (for example welcome dinner), and other materials related to the
            Conference. In some specific cases, some parts of your information may be shared with the Secretariat’s
            partner companies, which have been carefully selected and approved by us for the preparation of the
            Conference.
          </li>
          <li>To process any administrative or financial (related to the payment of your registration fee) matters that
            may arise in preparing for the Conference.
          </li>
          <li>To communicate with you on any matters related to the preparation of the Conference or to process your
            request regarding the Conference.
          </li>
          <li>To report on the result of the Conference to any supporting organizations such as the local convention
            bureau, some parts of your information may be used (for example, your name, affiliation, e-mail address,
            etc.).
          </li>
          <li>To improve the content on the official Website, for example, we may use cookies to find out which parts of
            the Website are visited the most often.
          </li>
          <li>For marketing and promotional purposes, for example, the Secretariat may send emails and direct mail to
            our registrants and prospective newsletter recipients who have signed-up for the Conference about Conference
            that we think may be of interest to them. We also may send you offer for discounts (e.g. early-bird
            registration) on the Website.
          </li>
        </ul>
        <h4 class="mt-3">Do We Share Your Information?</h4>
        <p>The information gathered may be shared with the ASCVTS 2024 Secretariat and our family associations. We also
          share the information with third parties, including responsible committees with which we have a relationship.
          For example:</p>
        <ul class="disc">
          <li>When you make a purchase, the Secretariat may share information about you and your transaction with other
            companies for the purpose of processing your transaction, including fraud prevention and credit card
            authorization.
          </li>
          <li>You also may make a purchase from the Website through a link from another website or search engine and may
            use their express checkout tool to do so. When you do, please be aware that both the Website and the other
            website or search engine will receive your information.
          </li>
          <li>In some cases, Conference may enter into a co-branding relationship with another website that offers you
            services that supplement Conference’ assortment (e.g. tours). In those cases, you may link from the Website
            to another site to apply for the program. In that case, both the Website and the other website may receive
            your information.
          </li>
          <li>The Website may team up with another website to conduct a sweepstakes or other event. In those cases, each
            of the participating websites may collect or receive personal information from you.
          </li>
          <li>For some of our events, the Website may provide you with a link to the supplier of that product so that
            you may get further information. If you link to the supplier's site, the supplier may collect or receive
            information about you.
          </li>
          <li>Like other committees, the Website may use third party advertising companies to serve ads (e.g. banners or
            links) on its behalf. These companies may employ cookies and action tags (also known as single pixel gifs or
            web beacons) to measure advertising effectiveness. Any information that these third parties collect via
            cookies and action tags is anonymous.
          </li>
        </ul>
        <p>In most of the above situations, these third parties will receive your information because you will be
          visiting their websites or using their links and, in doing so, you may provide information directly to them.
          You therefore should refer to their privacy policies to understand how they handle your information and what
          kinds of choices you have.</p>
        <p>The Secretariat also may share your personal information with outside companies that perform services for the
          Conference. For example, we may retain an outside company to manage a database containing certain
          participants’ information or to create and distribute an email offering. In those situations, the outside
          party is performing work for the Website, and the Secretariat includes language in its contracts stating that
          your information is to be used only to provide the services requested by the Secretariat.</p>
        <p>Additionally, the Secretariat may share account or other information when we believe it is necessary to
          comply with law or to protect the Secretariat’s interests or property. This may include sharing information
          with other companies, committees, lawyers, credit bureaus, agents or government agencies in connection with
          issues related to fraud, credit or debt collection.</p>
        <p>In the event that some or all of the business assets of the Conference are sold or transferred, we generally
          would transfer the corresponding information about the participants of the Conference.</p>
        <p>Finally, the Secretariat may share your personal information with unrelated outside companies so that they
          can directly promote the services to you if we feel that the companies offer services that we believe may be
          of interest. We also may provide you with the opportunity to create account on our website to receive email
          offers from other companies.</p>

        <h4 class="mt-3">What Choices Do You Have Regarding the Use of Your Information?</h4>
        <p>The Secretariat provides you with a number of choices regarding our handling of your personal
          information.</p>
        <p>If you wish to change your email preferences, please click on the unsubscribe link at the bottom of any
          Secretariat’s e-mail and describe the request such as “Please do not send me email," or “Keep my email address
          private."</p>
        <p>Note: Once you have submitted your email opt-out request, you should assume that it has been successfully
          received and your request is being processed. Please allow us 10 business days from when the request is
          received to complete the removal, as some of our promotions may already have been in process before you
          submitted your request.</p>

        <h4 class="mt-3">Third Party Sharing</h4>
        <p>You also may use the address and phone number above to request that we not reveal your personal information
          to unrelated third parties for marketing purposes. If you choose to write to the Secretariat, please include
          your name, address, and credit card account number to be used for settlement and state "NO THIRD PARTY
          SHARING" in your request.</p>

        <h4 class="mt-3">Can I Access My Information?</h4>
        <p>If you are a user who has already signed up for the Conference, you can update your information in “My Page,”
          and if you have questions about your status, you may also check them online in “My Page.”</p>
        <p>If you would like, you may also send an email to the Secretariat.</p>
        <p>Questions regarding your charge account may be forwarded to our Credit Customer Service Department.</p>

        <h4 class="mt-3">Is My Information Secure?</h4>
        <p>The Secretariat has put in place various physical, electronic, and managerial procedures to try to safeguard
          and secure the information we collect online. For example, private account and customer information is located
          on a secured server behind a firewall; it is not directly connected to the Internet. Encryption is a process
          by which a message or information is scrambled while it is in transit to the Secretariat. It is based on a key
          that has two different parts, public and private. The public part of the key is distributed to those with whom
          you want to communicate. The private part is for the recipient's use only. So long as you use a browser that
          allows for encryption, when you send personal information to the Secretariat, you use a public key to encrypt
          your personal information. If your information is intercepted during the transmission, it is scrambled and
          very difficult to decrypt. Once we receive your encrypted personal information, we use the private part of our
          key to decode it.</p>
        <p>Please note however that while we implement many security measures on this site, 100% security cannot be
          guaranteed.</p>
        <p>Thank you for your visit to the Website. If you have any questions or comments about this policy, please
          email the Secretariat (<a href="mailto:ASCVTS2024WUHAN@hotmail.com">ASCVTS2024WUHAN@hotmail.com</a>).</p>
      </div>
<!--      <div class="mt-3 dk:p-3 mb:py-3 mb:px-1 check-agree text-center font-bold relative">
        <a-checkbox v-model="agreeStatus">
          I agree to the collection and use of my personal information.
        </a-checkbox>
        <div v-if="agreeWarningShow" class="absolute block w-full text-center" style="color: red">
          Please agree to the service terms
        </div>
      </div>-->
<!--      <div class="card-footer text-center py-5 dk:px-32">
        <div class="grid grid-cols-2 gap-5 mt-2">
          <div class="cancel">
            <button class="w-full h-11 rounded"  @click="$emit('confirm', -1)" type="button">Cancel</button>
          </div>
          <div class="confirm">
            <button class="w-full h-11 rounded" type="button" @click="confirm">Confirm</button>
          </div>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
  export default {
    name: "PrivacyPolicy",
    data() {
      return {
        agreeStatus: false,
        agreeWarningShow: false
      }
    },
    watch: {
      agreeStatus: {
        handler(val) {
          if (val && this.agreeWarningShow) {
            this.agreeWarningShow = false
          }
        }
      }
    },
    methods: {
      confirm() {
        if (!this.agreeStatus) {
          this.agreeWarningShow = true;
          setTimeout(() => {
            this.agreeWarningShow = false;
          }, 5000);
          return
        }
        this.$emit('confirm', 1)
      }
    }
  }
</script>

<style lang="less" scoped>
  .privacyPolicy-container {
    margin: 0 auto;
    max-width: 1320px;
    .title {
      border-bottom: 1px solid #E8EBEE;
    }
    #privacy {
      font-family: "PublicSans Regular";
      font-size: 16px;
      h4 {
        font-family: "PublicSans bold";
        font-size: 20px;
      }
      ul {
        padding-left: 1em;
      }
      li {
        list-style: disc;
        list-style-position: outside;
      }
    }
    .card-footer {
      .cancel {
        button {
          background: rgb(222, 226, 230);
          color: #333333;

          &:hover {
            background: rgba(222, 226, 230, 0.8);
          }
        }
      }

      .confirm {
        button {
          background: rgb(248, 179, 1);
          color: #333333;

          &:hover {
            background: rgba(248, 179, 1, 0.8)
          }
        }
      }
    }

    .check-agree, .card-footer {
      background: #F5F7FA;
    }
  }
</style>
